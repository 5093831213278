import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ma-auto" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_app = _resolveComponent("v-app")!
  const _component_AppSnackbar = _resolveComponent("AppSnackbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_v_app, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_img, {
              width: "450",
              src: _ctx.getAppTheme ? require('@/assets/logo_dark.png') : require('@/assets/logo.svg')
            }, null, 8, ["src"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_progress_linear, {
                indeterminate: "",
                rounded: "",
                color: "primary"
              })
            ])
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_AppSnackbar),
        _createVNode(_component_router_view)
      ], 64))
}