
import {defineAsyncComponent, defineComponent} from 'vue'
import app from "@/mixins/app";
import user from "@/mixins/user";
import notification from "@/mixins/notification";
import theme from "@/store/modules/theme";
import appStore from "@/store/modules/app";
import mapStore from "@/store/modules/map";
import map from "@/mixins/map";
import taskbarStore from "@/store/modules/taskbar";
import manager from "@/store/modules/roles/manager";

export default defineComponent({

  components: {
    AppSnackbar: defineAsyncComponent(() =>
        import("@/components/AppSnackbar/AppSnackbar.vue")
    )
  },

  data() {
    return {
      loading: true,
    }
  },

  mixins: [
    app,
    user,
    notification,
    map
  ],

  // stores in dependence of which role is selected (in future)
  beforeUnmount() {
    this.$store.unregisterModule("theme", theme);
    this.$store.unregisterModule("app", appStore);
    this.$store.unregisterModule("taskbar", taskbarStore);
    this.$store.unregisterModule("manager", manager);
  },

  created() {
    this.$store.registerModule("theme", theme);
    this.$store.registerModule("app", appStore);
    this.$store.registerModule("taskbar", taskbarStore);
    this.$store.registerModule("manager", manager);

    if (!this.$store.hasModule('map')) {
      this.$store.registerModule("map", mapStore);
    }
    this.loadGoogleMapsAPI();

    document.addEventListener('app.request.error', (e) => {
      // @ts-ignore
      this.error(this.getErrorMessage(e.detail.error));
    });

    let useSystemTheme = this.getAppUseSystemTheme;
    if (useSystemTheme === true) {
      this.setAppTheme(this.getSystemTheme);
    } else {
      this.setAppTheme(this.getAppSavedTheme);
    }

    let router = this.$router;

    router.beforeEach((to, from, next) => {
      let isAuthorized = !!(this.getUser.id) && !!(this.$cookies.get('token'));
      let userRole = this.getUser?.role;

      let stepOneVerification = false;
      let stepTwoVerification = false;

      let toMiddleware: any = to.meta && to.meta.middleware ? to.meta.middleware : {};

      if (toMiddleware.requiresAuth) {
        if (isAuthorized) {
          stepOneVerification = true;
        } else {
          next('/login');
          localStorage.setItem('app.tried.to.access.page', to.path);
          return;
        }
      } else {
        stepOneVerification = true;
      }

      if (toMiddleware.requiresRole) {
        if (toMiddleware.requiresRole === userRole || toMiddleware.roleExceptions && toMiddleware.roleExceptions.includes(this.getUser.role)) {
          stepTwoVerification = true;
        } else {
          const redirectUri = `/${this.getUser.role}`;
          next(redirectUri);
          return;
        }
      } else {
        stepTwoVerification = true;
      }

      if (toMiddleware.redirectIfAuthorized) {
        if (isAuthorized) {
          const redirectUri = `/${this.getUser.role}`;
          if (from.path !== redirectUri) {
            next(redirectUri);
            return;
          }
        }
      }

      if (!isAuthorized) {
        if (to.path !== '/login') {
          next('/login');
          return;
        }
      }

      if (isAuthorized) {
        if (to.path === '/' || to.path === '') {
          const redirectUri = `/${this.getUser.role}`;
          next(redirectUri);
          return;
        }
      }

      if (stepOneVerification && stepTwoVerification) {
        next();
      } else {
        next(false);
      }
      return;
    });
  },

  mounted() {
    let promises = [];

    if (this.isAuthorised) {
      promises = this.boot();
    }

    const getTranslationsPromise = this.getTranslations();
    promises = [...promises, getTranslationsPromise];

    window.onload = (() => {
      Promise.all(promises).then(() => {
        this.loading = false;
      });
    });
  }

});
