import grpcWeb from "grpc-web";


// export const AuthInterceptor: (options, nextCall) => any = (options, nextCall) => {
//     console.log("AuthInterceptor !!!!!!!!!!!!!!!!!!!!!!!!!!");
//     const token = 'my-auth-token';
//     options.headers = {
//         Authorization: `Bearer ${token}`,
//         ...options.headers,
//     };
//     return nextCall(options);
// };

function getCookie(name: string): string {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => {
            return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
            return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null;
}

export default class AppUnaryInterceptor {
    intercept(
        request: grpcWeb.Request<any, any>,
        invoker: (
            request: grpcWeb.Request<any, any>
        ) => Promise<grpcWeb.UnaryResponse<any, any>>
    ) {

        // TODO: store somewhere in memory cache?
        const token = getCookie("token");
        // console.log("MY TOKEN", token);
        if (token) {
            request.getMetadata().Authorization = `Bearer ${token}`;
        }

        return invoker(request)
            .then((response: grpcWeb.UnaryResponse<any, any>) => {
                return response;
            }).catch((error: grpcWeb.RpcError) => {
                console.error(error);
                if (error.code === 16) {
                    const logOutEvent = new Event('app.askForCredentials');
                    document.dispatchEvent(logOutEvent);
                }

                document.dispatchEvent(new CustomEvent('app.request.error', {
                    detail: {
                        error: error
                    }
                }));

                return new Promise((resolve, reject) => {
                    reject(error)
                });
            })
    }
}