import {ContactServiceClient} from "@pb/contact/ContactServiceClientPb";
import {CargoTypeServiceClient} from "@pb/cargo_type/Cargo_typeServiceClientPb";
import {CategoryServiceClient} from "@pb/cargo_type/CategoryServiceClientPb";
import {CompanyServiceClient} from "@pb/company/CompanyServiceClientPb";
import {OrderServiceClient} from "@pb/order/OrderServiceClientPb";
import {AddressServiceClient} from "@pb/address/AddressServiceClientPb";
import {TrailerTypeServiceClient} from "@pb/trailer/TypeServiceClientPb";
import {CurrencyServiceClient} from "@pb/currency/CurrencyServiceClientPb";
import {DealServiceClient} from "@pb/deal/DealServiceClientPb";
import {AuthServiceClient, UserServiceClient} from "@pb/user/ServiceServiceClientPb";
import {GreeterClient} from "@pb/helloworld/HelloworldServiceClientPb";
import {TransporterDriverServiceClient} from "@pb/transporter_driver/Transporter_driverServiceClientPb";
import {TransporterServiceClient} from "@pb/transporter/TransporterServiceClientPb";
import {TransporterTrailerServiceClient} from "@pb/transporter_trailer/Transporter_trailerServiceClientPb";
import {TransporterTruckServiceClient} from "@pb/transporter_truck/Transporter_truckServiceClientPb";
import {LanguageServiceClient} from "@pb/language/LanguageServiceClientPb";
import AppUnaryInterceptor from "@/grpc/interceptor";
import {TransporterDirectionServiceClient} from "@pb/transporter_direction/Transporter_directionServiceClientPb";
import {CountryServiceClient} from "@pb/country/CountryServiceClientPb";
import {OrderCommentServiceClient} from "@pb/order_comment/Order_commentServiceClientPb";
import {OrderTransporterDirectionServiceClient} from "@pb/order_transporter_direction/Order_transporter_directionServiceClientPb";
import {
    OrderTransporterDirectionNoteServiceClient
} from "@pb/order_transporter_direction_note/Order_transporter_direction_noteServiceClientPb";
import {
    DealStatusChangeReasonServiceClient
} from "@pb/deal_status_change_reason/Deal_status_change_reasonServiceClientPb";
import {OrderMessageServiceClient} from "@pb/order_message/Order_messageServiceClientPb";
import {PassportServiceClient} from "@pb/passport/PassportServiceClientPb";
import {DocTypeServiceClient} from "@pb/doc_type/Doc_typeServiceClientPb";
import {
    DealTransporterDirectionNoteServiceClient
} from "@pb/deal_transporter_direction_note/Deal_transporter_direction_noteServiceClientPb";
import {OrderReportedProblemTypeServiceClient} from "@pb/order_reported_problem_type/TypeServiceClientPb";
import {OrderReportedProblemServiceClient} from "@pb/order_reported_problem/TypeServiceClientPb";
import {DealStatusCommentServiceClient} from "@pb/deal_status_comment/Deal_status_commentServiceClientPb";
import {DealStatusCommentTemplateServiceClient} from "@pb/deal_status_comment_template/Deal_status_comment_templateServiceClientPb";

export function GetGRPCClient(serviceClientName: any, options: { [index: string]: any; } = {
    'unaryInterceptors': [new AppUnaryInterceptor()],
}): any {
    // console.log("options => ", serviceClientName, options);
    // console.log("options => ", options);
    return new serviceClientName(process.env.VUE_APP_GRPC_ENDPOINT, {}, options)
}

export function GetDocTypeServiceClient(): DocTypeServiceClient {
    return GetGRPCClient(DocTypeServiceClient)
}
export function GetOrderReportedProblemTypesServiceClient(): OrderReportedProblemTypeServiceClient {
    return GetGRPCClient(OrderReportedProblemTypeServiceClient)
}
export function GetDealStatusCommentServiceClient(): DealStatusCommentServiceClient {
    return GetGRPCClient(DealStatusCommentServiceClient)
}
export function GetDealStatusCommentTemplateServiceClient(): DealStatusCommentTemplateServiceClient {
    return GetGRPCClient(DealStatusCommentTemplateServiceClient)
}
export function GetOrderReportedProblemServiceClient(): OrderReportedProblemServiceClient {
    return GetGRPCClient(OrderReportedProblemServiceClient)
}
export function GetPassportServiceClient(): PassportServiceClient {
    return GetGRPCClient(PassportServiceClient)
}
export function GetDealNotesServiceClient(): DealTransporterDirectionNoteServiceClient {
    return GetGRPCClient(DealTransporterDirectionNoteServiceClient)
}
export function GetLanguageServiceClient(): LanguageServiceClient {
    return GetGRPCClient(LanguageServiceClient)
}

export function GetOrderMessageServiceClient(): OrderMessageServiceClient {
    return GetGRPCClient(OrderMessageServiceClient)
}

export function GetContactServiceClient(): ContactServiceClient {
    return GetGRPCClient(ContactServiceClient)
}

export function GetCargoTypeServiceClient(): CargoTypeServiceClient {
    return GetGRPCClient(CargoTypeServiceClient)
}

export function GetOrderTransporterDirectionServiceClient(): OrderTransporterDirectionServiceClient {
    return GetGRPCClient(OrderTransporterDirectionServiceClient)
}

export function GetReasonsServiceClient(): DealStatusChangeReasonServiceClient {
    return GetGRPCClient(DealStatusChangeReasonServiceClient)
}
export function GetOrderTransporterDirectionNoteService(): OrderTransporterDirectionNoteServiceClient {
    return GetGRPCClient(OrderTransporterDirectionNoteServiceClient)
}

export function GetCargoTypeCategoryServiceClient(): CategoryServiceClient {
    return GetGRPCClient(CategoryServiceClient)
}

export function GetCompanyServiceClient(): CompanyServiceClient {
    return GetGRPCClient(CompanyServiceClient)
}

export function GetDirectionsServiceClient(): TransporterDirectionServiceClient {
    return GetGRPCClient(TransporterDirectionServiceClient)
}

export function GetTransporterServiceClient(): TransporterServiceClient {
    return GetGRPCClient(TransporterServiceClient)
}
export function GetCountryServiceClient(): CountryServiceClient {
    return GetGRPCClient(CountryServiceClient)
}
export function GetTrailerServiceClient(): TransporterTrailerServiceClient {
    return GetGRPCClient(TransporterTrailerServiceClient)
}
export function GetTruckServiceClient(): TransporterTruckServiceClient {
    return GetGRPCClient(TransporterTruckServiceClient)
}
export function GetDriverServiceClient(): TransporterDriverServiceClient {
    return GetGRPCClient(TransporterDriverServiceClient)
}
export function GetOrderCommentsServiceClient(): OrderCommentServiceClient {
    return GetGRPCClient(OrderCommentServiceClient)
}

export function GetOrderServiceClient(): OrderServiceClient {
    return GetGRPCClient(OrderServiceClient)
}

export function GetAddressServiceClient(): AddressServiceClient {
    return GetGRPCClient(AddressServiceClient)
}

export function GetTrailerTypeServiceClient(): TrailerTypeServiceClient {
    return GetGRPCClient(TrailerTypeServiceClient)
}

export function GetCurrencyServiceClient(): CurrencyServiceClient {
    return GetGRPCClient(CurrencyServiceClient)
}

export function GetDealServiceClient(): DealServiceClient {
    return GetGRPCClient(DealServiceClient)
}

export function GetUserServiceClient(): UserServiceClient {
    return GetGRPCClient(UserServiceClient)
}

export function GetGreeterClient(): GreeterClient {
    return GetGRPCClient(GreeterClient)
}

export function GetGreeterClientStream(): GreeterClient {
    return GetGRPCClient(GreeterClient, {})
}

export function GetAuthServiceClient(): AuthServiceClient {
    return GetGRPCClient(AuthServiceClient, {})
}


